<template>
  <b-button
    :disabled="disabled"
    @click="saveFn"
    size="sm"
    class="bg-transparent bordertransparent"
    variant="light">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="black"
      class="bi bi-check2"
      viewBox="0 0 16 16">
      <path
        d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
    </svg>
  </b-button>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    saveFn() {
      this.$emit("save");
    },
  },
};
</script>
