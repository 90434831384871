<template>
  <vue-good-table
    mode="remote"
    :totalRows="totalRecords"
    @on-cell-click="onCellClick"
    @on-page-change="onPageChange"
    @on-sort-change="onSortChange"
    @on-column-filter="onColumnFilter"
    @on-per-page-change="onPerPageChange"
    @on-search="onSearch"
    ref="account_table"
    :isLoading.sync="isLoading"
    :columns="fields"
    :rows="newRows"
    :row-style-class="rowStyleClassFn"
    :group-options="{
      enabled: true,
      headerPosition: 'bottom',
    }"
    :select-options="{
      enabled: true,
      selectOnCheckboxOnly: true,
      selectionText: 'rows selected',
      clearSelectionText: 'clear',
    }"
    @on-selected-rows-change="rowSum"
    styleClass="vgt-table accountTable striped "
    :search-options="{
      enabled: true,
    }"
    :pagination-options="{
      enabled: true,
      perPage: 25,
      perPageDropdown: [25, 50, 100, 200, 500],
      dropdownAllowAll: false,
    }">
    <template slot="emptystate"> No items available </template>
    <template
      slot="table-row"
      slot-scope="props"
      :class="{ rowActive: props.index == editRowIndex && editingMode }">
      <!--EDITABLE FIELDS-->
      <span v-if="props.column.type === 'date'">
        <FormulateInput
          v-if="
            props.column.editable && props.index == editRowIndex && editingMode
          "
          v-model="editRow[props.column.field]"
          type="date"
          :name="props.column.field"
          :placeholder="props.column.label"
          :validation="props.column.validation"
          :outer-class="props.column.outerClass" />
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </span>
      <span v-else-if="props.column.type === 'select'">
        <FormulateInput
          v-if="
            props.column.editable && props.index == editRowIndex && editingMode
          "
          v-model="editRow[props.column.field]"
          type="vue-select"
          :name="props.column.field"
          :placeholder="props.column.label"
          :options="props.column.options"
          :validation="props.column.validation"
          :outer-class="props.column.outerClass" />
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </span>
      <span v-else-if="props.column.type === 'number'">
        <FormulateInput
          v-if="
            props.column.editable && props.index == editRowIndex && editingMode
          "
          v-model="editRow[props.column.field]"
          type="currency"
          :name="props.column.field"
          :placeholder="props.column.label"
          :prefix="props.column.prefix"
          :decimalLimit="props.column.precision"
          :validation="props.column.validation"
          :outer-class="props.column.outerClass"
          :input-class="['text-right']" />
        <span v-else>
          <span>
            {{
              new Intl.NumberFormat("en-US", {
                maximumFractionDigits: props.column.precision,
                minimumFractionDigits: props.column.precision,
              }).format(props.formattedRow[props.column.field])
            }}
          </span>
        </span>
      </span>
      <span v-else-if="props.column.type === 'text'">
        <FormulateInput
          v-if="
            props.column.editable && props.index == editRowIndex && editingMode
          "
          v-model="editRow[props.column.field]"
          type="text"
          :name="props.column.field"
          :validation="props.column.validation"
          :placeholder="props.column.label"
          :outer-class="props.column.outerClass" />
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </span>
      <span v-else>
        {{ props.formattedRow[props.column.field] }}
      </span>
      <!--ACTIONS -->
      <span
        inline
        v-if="
          props.column.actions == 'actions' &&
          props.index == editRowIndex &&
          editingMode
        "
        class="actionSpan"
        style="font-weight: bold; color: black; display: flex">
        <saveButton :disabled="!areFieldsEdited" @save="saveFn()" />
        <cancelButton @cancel="cancelEditFn()" />
      </span>
      <span
        class="actions"
        inline
        flex
        v-else-if="props.column.field == 'Actions'">
        <editButton @edit="editFn(props)" />
        <deleteButton @delete="deleteFn(props.row)" />
      </span>
    </template>
    <template slot="loadingContent">
      &nbsp; <b-spinner type="border" label="Spinning"></b-spinner>
    </template>
  </vue-good-table>
</template>

<script>
import {
  editItem,
  postItem,
  deleteItem,
  serverSideTableGetter,
} from "../../../services/api.js";

import saveButton from "../../../components/buttons/saveButton.vue";
import cancelButton from "../../../components/buttons/cancelButton.vue";
import deleteButton from "../../../components/buttons/deleteButton.vue";
import editButton from "../../../components/buttons/editButton.vue";

export default {
  name: "editableTable",
  components: { saveButton, cancelButton, deleteButton, editButton },
  props: {
    storePath: {
      type: String,
    },
    storeData: {
      type: String,
    },
    fields: {
      type: Array,
    },
    id_property: {
      type: String,
    },
    item_name: {
      type: String,
    },
    initialSort: {
      type: Array,
    },
    initialFilter: {
      type: Object,
    },
    usesPortfolioSelector: {
      type: Boolean,
    },
  },
  data() {
    return {
      isLoading: false,
      totalRecords: 0,
      serverParams: {
        sort: [
          {
            field: "value_date",
            type: "asc",
          },
        ],
        columnFilters: {},
        page: 1, // what page I want to show
        perPage: 25, // how many items I'm showing per page
      },
      editRow: {},
      groupRow: [
        {
          name: "SubTotal",
          children: this.items,
        },
      ],
      checkboxRows: [],
      editingMode: false,
      editRowIndex: "none",
      selectedRow: "",
      sum: 0,
      edit_state: this.$store.state.table_edit_mode,
      sumOfFields: 0,
      sumElements: [],
    };
  },
  watch: {
    editMode: {
      immediate: false,

      handler() {
        if (this.edit_state == true) {
          let ObjToEdit = {};
          ObjToEdit.index = 0;
          ObjToEdit.row = this.editableObject;

          this.editFn(ObjToEdit);
        }
      },
    },
    selectedPortfolio: {
      immediate: false,
      handler() {
        if (this.usesPortfolioSelector) {
          this.isLoading = true;
          this.loadItems();
          this.$nextTick(function () {
            this.disablePortfolioFilter();
          });
        }
      },
    },
  },
  mounted() {
    this.isLoading = true;
    this.$store.commit(`SET_TABLE_EDIT_MODE`, false);
    this.$store.commit("SET_TABLE_FIELD", this.storePath);
    this.$store.commit("SET_TABLE_UNIQUE_KEY", this.id_property);
    this.updateParams({ sort: this.initialSort }); //Initial sort is defined on parent component and passed as a prop
    this.updateParams({ columnFilters: this.initialFilter });
    this.loadItems();
    this.disablePortfolioFilter();

    // Ver si no conviene que cada componente que usa la EditableTable haga el dispatch
    this.$store.dispatch("loadSecuritiesList");
    this.$store.dispatch("loadCurrenciesList");
  },

  computed: {
    serverParamsStringified() {
      const newServerParams = { ...this.serverParams };
      newServerParams.sort = JSON.stringify(newServerParams.sort);
      if (this.usesPortfolioSelector) {
        if (this.selectedPortfolioType === "portfolio") {
          newServerParams.columnFilters = {
            ...newServerParams.columnFilters,
            ...{ Portfolio_ID: this.selectedPortfolio },
          };
        } else if (this.selectedPortfolioType === "client") {
          newServerParams.columnFilters = {
            ...newServerParams.columnFilters,
            ...{ Client_ID: this.selectedPortfolio },
          };
        }
      }
      return newServerParams;
    },
    items() {
      return this.$store.state[`table_${this.storePath}`];
    },

    editMode() {
      let mode = this.$store.state.table_edit_mode;
      this.edit_state = mode;
      return mode;
    },
    editableObject() {
      return this.$store.state.table_editableObject;
    },
    newRows() {
      let rows = [
        {
          name: "",

          children: this.items,
        },
      ];
      let isThereAnyItems = Boolean(this.items) && this.items.length; // if items = undefined => false, pero si es [] entonces es true y puede evaluar el length sin que haya errores
      return isThereAnyItems ? rows : [];
    },
    selectedPortfolio() {
      return this.$store.state.selectedPortfolio;
    },
    selectedPortfolioType() {
      return this.$store.state.selectedPortfolioType;
    },
  },
  methods: {
    dateFormatFn(value) {
      try {
        let formattedDate = new Date(value).toISOString().split("T")[0];

        return formattedDate;
      } catch (error) {
        return value.substring(0, 10);
      }
    },
    onCellClick(params) {
      this.$emit("on-cell-click", params);
    },
    addLocalIDs(responseArray) {
      if (responseArray) {
        let newArray = responseArray.map((x, i) => {
          x.item_id = i + 1;

          return x;
        });

        return newArray;
      }
    },
    onSearch(params) {
      let debounceTimer;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(
        () => (
          this.updateParams({ searchTerm: params.searchTerm }), this.loadItems()
        ),
        1000
      );
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
      this.cancelEditFn();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: params,
      });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams({ columnFilters: params.columnFilters });
      this.loadItems();
    },
    loadItems() {
      serverSideTableGetter(this.storePath, this.serverParamsStringified)
        .then((response) => {
          let rows = response.data.data.rows;
          // Verifico si data.data es un array no vacio
          if (Array.isArray(rows) && rows.length > 0) {
            this.totalRecords = response.data.data.totalRecords;
            this.$store.commit(`SET_TABLE_${this.storeData}`, rows);
          } else {
            this.totalRecords = 0;
            this.$store.commit(`SET_TABLE_${this.storeData}`, []);
          }
        })
        .catch((error) => {
            this.totalRecords = 0;
            this.$store.commit(`SET_TABLE_${this.storeData}`, []);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    makeToast(variant = null, status, text) {
      this.$bvToast.toast([text], {
        title: `Code: ${status || "default"}`,
        variant: variant,
        solid: true,
        toaster: "b-toaster-top-center",
      });
    },

    rowSum(param) {
      let summableFields = this.fields
        .filter((field) => field.summable)
        .map((item) => item.field)
        .map((element) => {
          let val = param.selectedRows.reduce(
            (s, a) => s + Number(a[element]),
            0
          );
          return [element, val];
        });
      this.sumOfFields = Object.fromEntries(summableFields);

      this.checkboxRows = param.selectedRows.map((item) => item.originalIndex);
    },

    rowStyleClassFn(row) {
      if (this.checkboxRows.includes(row.originalIndex)) {
        return "selectableRow";
      }

      return row.originalIndex == this.editRowIndex && this.editingMode
        ? "activeRow"
        : "red";
    },

    areFieldsEdited() {
      let NotEqual = false;
      if (this.edit_state == true) {
        const itemToCheck = this.items.find(
          (item) => item[this.id_property] === this.editRow[this.id_property]
        );

        Object.keys(itemToCheck).forEach((key) => {
          NotEqual = NotEqual || itemToCheck[key] !== this.editRow[key];
        });
      }
      return NotEqual;
    },
    saveFn() {
      this.editRow.newitem ? this.postCall() : this.editCall();

      this.$store.commit(`SET_TABLE_EDIT_MODE`, "false");
      this.editingMode = false;

      //When api is linked, get response --> , this.makeToast(  "success", response.status,"Row edited successfully" );
    },
    // Función para desformatear los valores de los campos de tipo 'currency'
    formatCurrencyFields(obj) {
      const currencyFieldsByName = this.fields
        .filter((field) => field.editable === true && field.type === "number")
        .map((field) => field.field);

      currencyFieldsByName.forEach((field) => {
        if (obj.hasOwnProperty(field)) {
          obj[field] = this.filterNumber(obj[field]);
        }
      });

      return obj;
    },

    editCall() {
      let objectToPost = {};
      let id = this.editRow[this.id_property];
      let uri = `${this.storePath}/${id}`;
      // Limpiamos los campos de currency
      const formattedEditRow = this.formatCurrencyFields(this.editRow);

      // Después convertimos las claves a minúsculas
      const editRowLowerCase = Object.keys(formattedEditRow).reduce(
        (acc, key) => {
          acc[key.toLowerCase()] = formattedEditRow[key];
          return acc;
        },
        {}
      );

      objectToPost = this.cleanObjToPost(editRowLowerCase);
      this.putMethod(uri, objectToPost);
    },
    updateStore() {
      let cleanEditRow = this.editRow;
      // Desformatear los valores de los campos de tipo 'currency'
      // Entonces aqui las keys ya caen formateadas a minúscula
      cleanEditRow = this.formatCurrencyFields(cleanEditRow);

      const { newitem, newid, ...rest } = cleanEditRow;
      const payloadItem = rest;
      if (newid) {
        payloadItem[this.id_property] = newid;
      }

      let payload = this.items.map((item) => {
        if (item[this.id_property] === this.editRow[this.id_property]) {
          item = payloadItem;
        }
        return item;
      });
      this.$store.commit(`SET_TABLE_${this.storeData}`, payload);
    },

    cancelEditFn() {
      this.editingMode = false;
      this.$store.commit(`SET_TABLE_EDIT_MODE`, "false");
      this.editRowIndex = "none";
    },
    filterNumber(value) {
      // Remove sign, comma, and dot from the value
      const filteredValue = value.replace(/[^\d.-]/g, "");
      return filteredValue;
    },

    editFn(row) {
      this.editingMode = true;
      this.editRowIndex = row.index;
      this.editRow = { ...row.row };
      // Obtengo los campos con type = 'date' y editable = true
      const dateFields = this.fields
        .filter((field) => field.type === "date" && field.editable === true)
        .map((field) => field.field);
      // Formateo los values de los esos campos para que funcionen bien los inputs html 
      dateFields.forEach((dateField) => {
        if (this.editRow.hasOwnProperty(dateField)) {
          this.editRow[dateField] = this.dateFormatFn(this.editRow[dateField]);
        }
      });
    },

    deleteCall(back_id) {
      let uri = `${this.storePath}/${back_id}`;
      deleteItem(uri).then((response) =>
        response.status == 200
          ? (this.localDelete(back_id),
            this.makeToast(
              "success",
              response.status,
              `${this.item_name} deleted successfully.`
            ))
          : false
      );
    },
    localDelete(back_id) {
      this.$store.commit(
        `SET_TABLE_${this.storeData}`,
        this.items.filter((item) => item[this.id_property] !== back_id)
      );
    },

    postCall() {
      let objectToPost = {};
      let uri = `${this.storePath}`;
      const lowercaseKeys = (obj) =>
        Object.keys(obj).reduce((acc, key) => {
          acc[key.toLowerCase()] = obj[key];
          return acc;
        }, {});

      let objectToPostLowerCase = lowercaseKeys(this.editRow); //temporary fn until transfers comes with lowercase keys
      let cleanObj = this.cleanObjToPost(objectToPostLowerCase);

      objectToPost[`${this.storePath}`] = [cleanObj];

      this.postMethod(uri, objectToPost);
    },

    deleteFn(row) {
      swal({
        title: "Are you sure?",
        text: `Once deleted, you will not be able to recover this ${this.item_name}.`,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          row.newitem
            ? this.localDelete(row[this.id_property])
            : this.deleteCall(row[this.id_property]);
          swal(` ${this.item_name} was sucessfully deleted.`, {
            icon: "success",
          });
        } else {
          swal(`The  ${this.item_name} was not deleted.`);
        }
      });
    },
    putMethod(uri, objectToPost) {
      editItem(uri, objectToPost).then((response) =>
        response.status == 200
          ? (this.updateStore(),
            this.makeToast(
              "success",
              response.status,
              "Row edited successfully"
            ))
          : false
      );
    },
    postMethod(uri, objectToPost) {
      postItem(uri, objectToPost).then((response) =>
        response.status == 201 || response.status == 200
          ? ((this.editRow.newid = response.data.data[0]),
            this.updateStore(),
            this.makeToast(
              "success",
              response.status,
              "Row created successfully"
            ))
          : false
      );
    },
    cleanObjToPost(obj) {
      const {
        newitem,
        item_id,
        vgt_id,
        vgtSelected,
        originalindex,
        vgtselected,
        ...rest
      } = obj;
      return rest;
    },
    disablePortfolioFilter() {
      //if a portfolio is selected it disables portfolio filter
      if (this.usesPortfolioSelector) {
        this.$refs.account_table.columns.filter(
          (column) => column.field.toLowerCase() == "portfolio_id"
        )[0].filterOptions.enabled = !Boolean(this.selectedPortfolio);
      }
    },
  },
};
</script>

<style scoped>
/deep/ input[type="number"]::-webkit-outer-spin-button,
/deep/ input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/deep/ input[type="number"] {
  -moz-appearance: textfield;
}

/* .input-Date {
  width: 135px;
}
.input-year {
  width: 10vw;
}
.input-Currency {
  width: 120px;
}
.input-Portfolio_ID {
  width: 200px;
}
.select-asset {
  min-width: 350px;
}
.select-currency {
  min-width: 250px;
}

@media screen and (min-width: 576px) {
  .input-Date {
    width: 150px;
  }
  .input-Portfolio_ID {
    width: 200px;
  }
  .select-asset {
    width: 190px;
  }
  .input-amount {
    width: 120px;
  }
}

.input-side {
  min-width: 18px;
}
.input-type {
  min-width: 55px;
}
.input-tif {
  min-width: 55px;
}
.input-status {
  min-width: 108px;
}

.input-name {
  max-width: 180px;
}

.input-Description {
  max-width: 240px;
}

.input-bbg_ticker {
  max-width: 180px;
}

.input-ticker {
  max-width: 80px;
}

.input-principal {
  width: 110px;
} */
</style>
