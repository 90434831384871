var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vue-good-table',{ref:"account_table",attrs:{"mode":"remote","totalRows":_vm.totalRecords,"isLoading":_vm.isLoading,"columns":_vm.fields,"rows":_vm.newRows,"row-style-class":_vm.rowStyleClassFn,"group-options":{
    enabled: true,
    headerPosition: 'bottom',
  },"select-options":{
    enabled: true,
    selectOnCheckboxOnly: true,
    selectionText: 'rows selected',
    clearSelectionText: 'clear',
  },"styleClass":"vgt-table accountTable striped ","search-options":{
    enabled: true,
  },"pagination-options":{
    enabled: true,
    perPage: 25,
    perPageDropdown: [25, 50, 100, 200, 500],
    dropdownAllowAll: false,
  }},on:{"on-cell-click":_vm.onCellClick,"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange,"on-search":_vm.onSearch,"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event},"on-selected-rows-change":_vm.rowSum},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.type === 'date')?_c('span',[(
          props.column.editable && props.index == _vm.editRowIndex && _vm.editingMode
        )?_c('FormulateInput',{attrs:{"type":"date","name":props.column.field,"placeholder":props.column.label,"validation":props.column.validation,"outer-class":props.column.outerClass},model:{value:(_vm.editRow[props.column.field]),callback:function ($$v) {_vm.$set(_vm.editRow, props.column.field, $$v)},expression:"editRow[props.column.field]"}}):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])],1):(props.column.type === 'select')?_c('span',[(
          props.column.editable && props.index == _vm.editRowIndex && _vm.editingMode
        )?_c('FormulateInput',{attrs:{"type":"vue-select","name":props.column.field,"placeholder":props.column.label,"options":props.column.options,"validation":props.column.validation,"outer-class":props.column.outerClass},model:{value:(_vm.editRow[props.column.field]),callback:function ($$v) {_vm.$set(_vm.editRow, props.column.field, $$v)},expression:"editRow[props.column.field]"}}):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])],1):(props.column.type === 'number')?_c('span',[(
          props.column.editable && props.index == _vm.editRowIndex && _vm.editingMode
        )?_c('FormulateInput',{attrs:{"type":"currency","name":props.column.field,"placeholder":props.column.label,"prefix":props.column.prefix,"decimalLimit":props.column.precision,"validation":props.column.validation,"outer-class":props.column.outerClass,"input-class":['text-right']},model:{value:(_vm.editRow[props.column.field]),callback:function ($$v) {_vm.$set(_vm.editRow, props.column.field, $$v)},expression:"editRow[props.column.field]"}}):_c('span',[_c('span',[_vm._v(" "+_vm._s(new Intl.NumberFormat("en-US", { maximumFractionDigits: props.column.precision, minimumFractionDigits: props.column.precision, }).format(props.formattedRow[props.column.field]))+" ")])])],1):(props.column.type === 'text')?_c('span',[(
          props.column.editable && props.index == _vm.editRowIndex && _vm.editingMode
        )?_c('FormulateInput',{attrs:{"type":"text","name":props.column.field,"validation":props.column.validation,"placeholder":props.column.label,"outer-class":props.column.outerClass},model:{value:(_vm.editRow[props.column.field]),callback:function ($$v) {_vm.$set(_vm.editRow, props.column.field, $$v)},expression:"editRow[props.column.field]"}}):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")]),(
        props.column.actions == 'actions' &&
        props.index == _vm.editRowIndex &&
        _vm.editingMode
      )?_c('span',{staticClass:"actionSpan",staticStyle:{"font-weight":"bold","color":"black","display":"flex"},attrs:{"inline":""}},[_c('saveButton',{attrs:{"disabled":!_vm.areFieldsEdited},on:{"save":function($event){return _vm.saveFn()}}}),_c('cancelButton',{on:{"cancel":function($event){return _vm.cancelEditFn()}}})],1):(props.column.field == 'Actions')?_c('span',{staticClass:"actions",attrs:{"inline":"","flex":""}},[_c('editButton',{on:{"edit":function($event){return _vm.editFn(props)}}}),_c('deleteButton',{on:{"delete":function($event){return _vm.deleteFn(props.row)}}})],1):_vm._e()]}}])},[_c('template',{slot:"emptystate"},[_vm._v(" No items available ")]),_c('template',{slot:"loadingContent"},[_vm._v("   "),_c('b-spinner',{attrs:{"type":"border","label":"Spinning"}})],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }